.ant-checkbox-group-item {
  display: flex;
  margin-right: 8px;
}
hr {
  border: 0 solid rgb(0 0 0);
  border-top: 1px solid rgb(240, 240, 240);
}

.ant-tree {
  background: none;
}