div.divider {
  padding-top: 0em;
  padding-bottom: 0em;
  margin-top:0.5em;
  margin-bottom: 0.5em;
}
div.divider:first-child {
  padding-top: 0em;
  margin-top:0em;
}

hr.divider {
  padding:0;
  margin: 12px -16px 10px;
  border: 0;
  border-top: 1px solid #e4e4e4;
}

hr.divider:first-child {
  margin: -4px -16px 0px;
  border-top: 0;
}