#statisticsview {
  height: 100vh;
  background-color: rgb(233, 233, 233);
  padding:16px;
}

.ant-card-body {
  height: 100px;
}

#mapCard div.ant-card-body {
  height: 50vh;
}

#timeLine div.ant-card-body {
  height: 100px;
}
