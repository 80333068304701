
#appcontainer {
  background-color: #000000;
  height: 100vh;
  display: grid;
  grid-gap: 0em;
  padding: 0em;
  grid-template-columns: 14em 1fr;
  grid-template-areas: "left center";
  overflow: hidden;
}

.navbar {
  grid-area: left;
  z-index: 10;
  position: relative;
}
h2.navHead, h3.navHead {
  color:aliceblue;
}

div.navHead {
  margin: 1em;
  margin-bottom: 3em;
  height:auto;
}