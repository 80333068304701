
#mapview {
    background-color: rgb(244, 244, 245);
    color: rgb(64, 64, 60);
    height: 100vh;
    display: grid;
    grid-gap: 0em;
    padding: 0em;
    grid-template-columns: 17em 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas: "left center" "left footer";
    overflow: hidden;
}

.widget {
    background-color: rgb(250, 250, 250);
    box-shadow: 0px 0px 1px rgb(100, 100, 120, 0.1), 2px 2px 6px rgba(0, 0, 0, 0.03);
    /*padding: 0.0em;*/
    border-radius: 0px;
}

.widget > h1, .widget > h2 {
    margin: 0px 0px 10px 0px;
    padding:10px;
}

.map-sidebar {
    grid-area: left;
    display: grid;
    grid-gap: 0em;
    grid-template-rows: 1fr;
    z-index: 10;
}

.map-sidebar > .widget {
  overflow-y: auto;
}

main {
    grid-area: center;
    overflow: hidden;
}

footer {
    text-align: center;
    grid-area: footer;
    overflow: hidden;
    padding: 8px;
}

/* compensate for container padding */


#options {
    display: grid;
    grid-template-rows: auto auto;
    overflow: hidden;
}

#settings {
    padding-bottom: 0em;
}


